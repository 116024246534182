<template>
  <VuePhoneNumberInput
    v-model="inputVal"
    default-country-code="US"
    :preferred-countries="['US']"
    required
    @update="onUpdate"
  />
</template>
<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
export default {
  components: { VuePhoneNumberInput },
  props: {
    value: { type: [String, Number], default: null },
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    onUpdate(payload) {
      this.$emit("update", payload);
    },
  },
};
</script>
<style scoped>
.vue-phone-number-input :deep(.country-selector__label) {
  display: none;
}
.vue-phone-number-input :deep(.input-tel__label) {
  display: none;
}

div :deep(.input-tel.has-hint .input-tel__input) {
  padding-top: 0;
}
div :deep(.input-tel.has-value .input-tel__input) {
  padding-top: 0;
}

div :deep(.country-selector.has-value .country-selector__input) {
  padding-top: 0;
}
div :deep(.country-selector__country-flag) {
  top: 14px;
}
</style>
